import React from "react";
import Seo from "../components/seo";

import { Box } from "@mui/material";
import Page from "../components/page";
import { StaticImage } from "gatsby-plugin-image";

const EngHomepage = props => {
  return (
    <>
      <Seo title="About the publishing house and Dialogi" />
      <Box
        sx={theme => ({
          bgcolor: theme.palette.white,
          height: "100%",
        })}
      >
        <Page className={"content"}>
          <section>
            <h1>Rights Catalogues</h1>
            <Box
              sx={theme => ({
                "& a": {
                  textDecoration: "underline",
                  fontWeight: "bold",
                },
              })}
            >
              <p>
                <a
                  href="https://backend.aristej.si/uploads/aristej_rights_catalogue_2023_eng_19a381d318.pdf"
                  target="_blank"
                >
                  Rights Catalogue – Frankfurt 2023 (PDF)
                </a>
              </p>
              <p>
                <a
                  href="https://backend.aristej.si/uploads/aristej_rights_catalogue_2023_de_ebb4ff9732.pdf"
                  target="_blank"
                >
                  Urheberrechts-katalog – Frankfurt 2023 (PDF)
                </a>
              </p>
              <p>
                <a
                  href="https://backend.aristej.si/uploads/rights_catalogue_humanities_28f1e79cc7.pdf"
                  target="_blank"
                >
                  Rights Catalogue – Humanities and social sciences nonfiction
                  for young adults – spring 2025 (PDF)
                </a>
              </p>
              <p>
                <a
                  href="https://backend.aristej.si/uploads/rights_catalogue_kamishibai_a2b9376226.pdf"
                  target="_blank"
                >
                  Rights Catalogue – Kamishibai-2025 (PDF)
                </a>
              </p>
            </Box>

            <h1>About the publishing house</h1>
            <p>
              Aristej Publishing House was founded in 1993 by Emica Antončič,
              specialist in Slovene language and comparative literature, after
              she left her job as a dialect coach at the Slovenian National
              Theatre Drama Maribor. The first published books were her own
              work, the picture books in the Ada collection, which also marked
              the beginning of the publisher’s children’s fiction programme.
              This continues today in the Iva collection and with other books
              and picture books.
            </p>
            <p>
              A year later Aristej accepted into its programme the journal{" "}
              <strong>Dialogi,</strong> one of Slovenia’s oldest journals of
              culture, ensuring its continued existence and revamping it
              editorially.
            </p>
            <p>
              The book <strong>Theatre Concepts for Young Readers</strong> by
              Blaž Lukan (1996) gave rise to the now legendary popular science
              collection <strong>Concepts (Pojmovniki),</strong> in which new
              books are still being published. The publishing house built on the
              field of popular science for young people in 2020 with a new
              collection,{" "}
              <strong>
                What Was It Like Before There Were (Kako je bilo, ko še ni
                bilo).
              </strong>
            </p>
            <p>
              The books from Breda Varl's <strong>My Puppets</strong> collection
              were the first Slovenian handbooks for puppet technologies and the
              production of theatre puppets, which brought much-needed help to
              preschool teachers, educators, and amateur puppeteers. The
              collection was later published in translation in Croatia as well,
              and from it grew the collection{" "}
              <strong>Puppet Stage (Lutkovni oder),</strong> which publishes
              plays for children.
            </p>

            <p>
              In 1998, Aristej began publishing academic works in the humanities
              and social sciences in the Dialogi book collection. A number of
              original works have been published to date, as well as
              translations of fundamental works that were not available in
              Slovene before. The Dialogi collection primarily covers
              anthropology, sociology, philosophy, social psychology, cognitive
              science, linguistics, film studies, women's studies, and literary
              history.
            </p>
            <p>
              In 2018, we published the first Slovenian handbook for creating
              kamishibai, <strong>The Art of Kamishibai</strong> by Jelena
              Sitar, and then the first Slovenian printed kamishibai{" "}
              <strong>Janček the Hedgehog (Ježek Janček, 2021),</strong> which
              is already being followed by new ones. Kamishibai is a traditional
              Japanese art of telling stories with pictures in a small wooden
              stage (butai), which is becoming popular all over the world as a
              form of resistance against the widespread digitization of society.
            </p>
            <p>
              The publishing house has built up a good reputation through its
              selective and high quality programme based on fresh and original
              ideas. Many of Aristej’s books have opened up new fields and
              topics which prior to that time had not received attention in
              Slovenia.
            </p>
            <p>
              In addition to original publishing ideas, the publishing house is
              characterized by editorial thinking outside the box and concern
              for the Slovene language, especially for the development of
              Slovene terminology in the humanities and social sciences.
            </p>
          </section>

          <section>
            <h1>About Dialogi</h1>
            <p>
              Published in Maribor since 1965, Dialogi is one of Slovenia’s
              oldest cultural journals. It was created through the efforts of
              many generations of Maribor’s intellectuals and literati; for the
              most part it has been predominantly a literary journal with a
              local emphasis, but during certain periods it has also covered
              wider cultural topics.
            </p>
            <p>
              From 1994 on, when Založba Aristej (Aristej Publishing House)
              became its publisher and Emica Antončič its editor-in-chief, it
              began to develop as a general cultural journal with the subtitle{" "}
              <strong>Journal of Culture and Society</strong> aimed at a
              national and international readership. The editorial board
              publishes and encourages critical thought from various fields in
              the arts, humanities, and social sciences, with a particular focus
              on civil society, independent culture, and socially engaged
              intellectuals.
            </p>
            <p>
              The discerning reader can find in Dialogi in-depth interviews with
              Slovenian and foreign cultural figures and reviews of domestic and
              international arts events, including those frequently overlooked
              by the mass media. A particular feature of the magazine, evoking
              the most responses, is the thematic sets of articles devoted to a
              specific topic of current interest. In keeping with the tradition
              of Slovenian cultural journals, Dialogi also publishes original
              and translated works of fiction.
            </p>
            <p>
              In April of 1999 Dialogi became the first magazine from Slovenia
              to partner with Eurozine, an international network which has
              joined together leading European cultural journals since 1989,
              facilitating a rich and free European dialogue and public space.
              Selected articles from Dialogi in English translation or the
              original can be found on the Eurozine website.
            </p>

            <Box mt={8} mb={8}>
              <a
                href="https://www.eurozine.com/journals/dialogi/"
                rel="noreferrer"
                target={"_blank"}
              >
                <StaticImage
                  src="../../images/eurozine-logo.svg"
                  alt="Eurozine Logo"
                  placeholder="none"
                />
              </a>
            </Box>
            <p>
              Publication of Dialogi is made possible through the financial
              support of the Slovenian Book Agency and the Municipality of
              Maribor.
            </p>
            <h3>Editorial board, contact</h3>
            <Box
              sx={theme => ({
                display: {
                  md: "flex",
                },
                gap: theme.spacing(10),
              })}
            >
              <Box>
                <p>
                  <strong>Emica Antončič</strong> (editor-in-chief)
                  <br />
                  <strong>Igor Bašin</strong> (music)
                  <br />
                  <strong>Primož Jesenko</strong> (theatre)
                  <br />
                  <strong>Petra Kolmančič</strong> (literature)
                  <br />
                  <strong>Meta Kordiš</strong> (independent culture)
                  <br />
                  <strong>Nataša Kovšca</strong> (visual arts)
                  <br />
                  <strong>Matic Majcen</strong> (film)
                  <br />
                  <strong>Ciril Oberstar</strong> (social sciences)
                  <br />
                  <strong>Boris Vezjak</strong> (critical thought)
                  <br />
                  <strong>Jasmina Založnik</strong> (feminist approaches)
                  <br />
                </p>
              </Box>
              <Box>
                <p>
                  Copy editor for Slovene language:
                  <br />
                  <strong>Emica Antončič</strong>
                </p>
                <p>
                  Copy editor for English language and translator into English:
                  <br />
                  <strong>Jean McCollister</strong>
                </p>
                <p>
                  Design:
                  <br />
                  <strong>Matjaž Wenzel / MILIMETER</strong>
                </p>
              </Box>
            </Box>
            <h3>Contact us</h3>
            <p>
              <strong>Address:</strong>
              <br />
              Dialogi, Založba Aristej, p.p. 115, 2116 Maribor
            </p>
            <p>
              <strong>T)</strong> +386 2 250 21 93, +386 2 250 21 94
              <br />
              <strong>F)</strong> +386 2 252 18 97
              <br />
              <strong>E)</strong>{" "}
              <a href="mailto:info@aristej.si">info@aristej.si</a>
            </p>
          </section>
        </Page>
      </Box>
    </>
  );
};

export default EngHomepage;
